import http from "../utils/request"
import request from "../utils/request";


//查询列表
export function listMember(data) {
    return http({
        url: '/admin/member/list',
        method: 'post',
        data: data
    })
}

//设置状态
export function setStatus(data) {

    return http({
        url: '/admin/member/status',
        method: 'post',
        data: data
    })
}

//单个用户进店数据列表
export function listRecord(data) {

    return http({
        url: '/admin/member/record',
        method: 'post',
        data: data
    })
}

//所以用户进让数据列表
export function listShopRecord(data) {

    return http({
        url: '/admin/member/shoprecord',
        method: 'post',
        data: data
    })
}