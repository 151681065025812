<template>
  <div class="app-container">
    <div class="searchForm_box">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input
            placeholder="請輸入內容"
            v-model="searchForm.searchTxt"
            class="input-with-select"
          >
            <el-select
              v-model="searchForm.searchChoose"
              slot="prepend"
              placeholder="請選擇"
              style="width: 130px"
            >
              <el-option label="手機號" value="phone"></el-option>
              <el-option label="暱稱" value="nickname"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索
          </el-button>
          <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置
                    </el-button> -->
        </el-form-item>
      </el-form>
    </div>

    <el-table
      row-key="Id"
      :data="barcodeList"
      border
      class="table"
      v-loading="loading"
    >
      <!-- <el-table-column label="頭像" prop="img" width="50">
                <template slot-scope="scope">
                    <el-image style="width: 25px; height: 25px" :src="scope.row.headimgurl != '' && scope.row.headimgurl != null ? scope.row.headimgurl : require('@/assets/image/default.jpg')" fit="contain">
                    </el-image>
                </template>
            </el-table-column> -->
      <el-table-column
        label="暱稱"
        prop="nickname"
        width="150"
      ></el-table-column>
      <el-table-column label="手機" prop="phone"></el-table-column>
      <el-table-column label="店鋪" prop="shop.shop_name"></el-table-column>
      <!-- <el-table-column label="人臉" prop="face_photo_url">
                <template slot-scope="scope">
                    <span class="ant-image-mask">
                    <el-image @click="imgViewDialog_show(scope.row.face_photo_url)" v-if="scope.row.face_id" style="width: 50px; height: 50px" :src="scope.row.face_photo_url" fit="contain"></el-image>
                    </span>
                </template>
            </el-table-column> -->
      <!-- <el-table-column label="unionid" prop="unionid" width="250"></el-table-column>
            <el-table-column label="小程序openid" prop="applet_openid" width="250"></el-table-column> -->
      <el-table-column label="進店次數" prop="count" width="100" align="center">
        <!-- <template slot-scope="scope">
          <span class="clickDetialBtn" @click="showDetail(scope.row)">{{
            scope.row.count
          }}</span>
        </template> -->
      </el-table-column>
      <!-- <el-table-column label="狀態" prop="status">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#cccccc"
            :active-value="0"
            :inactive-value="1"
            @change="setStatus(scope.row, 'status')"
          >
          </el-switch>
        </template>
      </el-table-column> -->
      <el-table-column label="註冊時間" prop="create_time"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-table :data="record">
        <el-table-column property="create_time" label="日期" width="200">
          <template slot-scope="scope">
            {{ formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column
          property="shop_name"
          label="店鋪名"
          width="200"
        ></el-table-column>
        <el-table-column property="type" label="進店類型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">掃碼</span>
            <span v-else>刷臉</span>
          </template>
        </el-table-column>
        <el-table-column property="status" label="進店狀態">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1" style="color: green">成功</span>
            <span v-else style="color: red">失敗</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChangeRecord"
        @current-change="handleCurrentChangeRecord"
        :current-page="page_record"
        :page-size="pageSize_record"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="record_total"
      >
      </el-pagination>
    </el-dialog>

    <!-- 彈出dialog大圖 -->
    <el-dialog
      title
      :visible.sync="imgViewDialogVisible"
      class="imgView-dialog"
      :modal="false"
    >
      <div>
        <img
          class="img"
          width="100%"
          height="100%"
          :src="imgViewDialog_imgSrc"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listMember, setStatus, listRecord } from "@/api/member";
import { isRelogin } from "../../utils/request";

const img = require("@/assets/image/default.jpg");
export default {
  name: "list",
  data() {
    return {
      imgViewDialogVisible: false,
      imgViewDialog_imgSrc: "",
      dialogVisible: false,
      loading: true,
      searchForm: {
        searchTxt: "",
        searchChoose: "phone",
        phone: "",
        nickname: "",
      },
      page: 1,
      pageSize: 50,
      total: 0,
      record_total: 0,
      page_record: 1,
      pageSize_record: 10,
      recode_total: 0,
      barcodeList: [],
      record: [],
      title: "進店記錄",
      member_id: "",
      member_phone: "",
    };
  },
  methods: {
    imgViewDialog_show: function (item) {
      this.imgViewDialogVisible = true;
      this.imgViewDialog_imgSrc = item;
    },
    showDetail(row) {
      this.title = "進店記錄 " + row.phone;
      this.member_id = row.member_id;
      this.getRecordDetail();
    },
    getRecordDetail() {
      let params = {
        page: this.page_record,
        pageSize: this.pageSize_record,
        member_id: this.member_id,
      };
      this.dialogVisible = true;

      listRecord(params)
        .then((response) => {
          this.record = response.data.list;
          this.record_total = response.data.count;
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    getBarcodeList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        phone:
          this.searchForm.searchChoose == "phone"
            ? this.searchForm.searchTxt
            : "",
        nickname:
          this.searchForm.searchChoose == "nickname"
            ? this.searchForm.searchTxt
            : "",
      };
      this.loading = true;
      listMember(params).then((response) => {
        this.barcodeList = response.data.list;
        this.total = response.data.count;
        this.loading = false;
      });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getBarcodeList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getBarcodeList();
    },
    // 分頁
    handleSizeChangeRecord(val) {
      this.pageSize_record = val;
      this.getRecordDetail();
    },
    handleCurrentChangeRecord(val) {
      this.page_record = val;
      this.getRecordDetail();
    },
    handleQuery() {
      this.page = 1;
      this.getBarcodeList();
    },
    resetQuery() {
      this.searchForm = {
        searchChoose: "goods_no",
      };
    },

    setStatus(row, tag) {
      let val = "";
      let params = {
        member_id: row.member_id,
      };
      let btn_txt = "";

      val = row.status;
      params["status"] = val;
      btn_txt = "禁止顧客";

      let txt = "";
      let old_val = "";
      if (val == 1) {
        txt = "開啟";
        old_val = 0;
      } else {
        txt = "關閉";
        old_val = 1;
      }

      this.$modal
        .confirm(
          "確定要<span style='color:red'>" +
            txt +
            btn_txt +
            "'" +
            row.nickname +
            "'進店</span>？"
        )
        .then(function () {
          return setStatus(params);
        })
        .then(() => {
          this.$modal.msgSuccess("操作成功");
        })
        .catch(() => {
          row.status = old_val;
        });
    },
  },
  created() {
    this.getBarcodeList();
  },
};
</script>
<style lang="scss">
.agentManage {
  padding: 10px;

  > .searchForm_box {
    padding: 10px;
    background: #fff;
    margin: 10px 0;

    .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.clickDetialBtn {
  color: rgb(24, 144, 255);
  cursor: pointer;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-image-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.imgView-dialog {
  width: 800px;
  height: 800px;
  align-content: center;
  text-align: center;
}
</style>
